<template>
  <div class="mx-2 my-2">
    <div style="display: inline-flex" class="mb-2">
      <v-icon large class="mr-2">mdi-table-arrow-down</v-icon>
      <h1>Liste des imports</h1>
    </div>
    <v-card class="mb-12">
      <div class="px-4 py-4">
        <v-btn
          dark
          color="primary"
          @click="sendAllInvitation()"
        >
          Envoyer toutes les invitations
        </v-btn>
        <v-data-table
          :headers="headers"
          :footer-props="footerProps"
          :items="getContacts"
          :loading="loading"
          :options.sync="options"
          :server-items-length="getContactsTotalItems"
          class="mt-4 elevation-10"
        >
          <template v-slot:item.invitationSentAt="{ item }">
            <v-chip
              v-if="item.invitationSentAt"
              color="green"
              text-color="white"
            >
              {{ item.invitationSentAt.toLocaleString() }}
            </v-chip>
            <v-btn
              v-else-if="item.eligibleToInvitation"
              dark
              small
              color="primary"
              @click="sendInvitation(item)"
            >
              Envoyer l'invitation
            </v-btn>
            <v-chip
              v-else
              color="primary"
              text-color="white"
            >
              Innéligible
            </v-chip>
          </template>
          <template v-slot:item.hasRelationship="{ item }">
            <v-chip
              v-if="item.hasRelationship"
              color="green"
              text-color="white"
            >
              Oui
            </v-chip>
            <v-chip
              v-else
              color="red"
              text-color="white"
            >
              Non
            </v-chip>
          </template>
          <template v-slot:item.accountLinked="{ item }">
            <v-chip
              v-if="item.accountLinked"
              color="green"
              text-color="white"
            >
              Oui
            </v-chip>
            <v-chip
              v-else
              color="red"
              text-color="white"
            >
              Non
            </v-chip>
          </template>
        </v-data-table>
      </div>        
    </v-card>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'Import',

  components: {  },

  computed: {
    ...mapGetters({
      getContacts: '_import/getContacts',
      getContactsTotalItems: '_import/getContactsTotalItems'
    })
  },

  data: function() {
    return {
      loading: true,
      headers: [
        { text: 'Nom', value: 'lastname', filterable: false, sortable: false },
        { text: 'Prénom', value: 'firstname', filterable: false, sortable: false },
        { text: 'E-mail', value: 'email', filterable: false, sortable: false },
        { text: 'Téléphone', value: 'phone', filterable: false, sortable: false },
        { text: 'Invitation envoyée', value: 'invitationSentAt' },
        { text: 'Ami ?', value: 'hasRelationship', filterable: false, sortable: false },
        { text: 'Inscrit', value: 'accountLinked', filterable: false, sortable: false }
      ],
      footerProps: {
        "items-per-page-all-text": "100",
        "items-per-page-options": [
          5, 10, 15, 50 , 100
        ]
      },
      options: {}
    }
  },
  watch: {
    options: {
      handler () {
        this.loading = true;

        const orderPayload = {};

        for (let index = 0; index < this.options.sortBy.length; index++) {
          const option = this.options.sortBy[index];
          orderPayload[option] = this.options.sortDesc[index] ? "desc" : "asc"
        }

        this.$store.dispatch('_import/get', { 
          uuid: this.$route.params.uuid,
          page: this.options.page,
          itemsPerPage: this.options.itemsPerPage,
          order: orderPayload
        }).finally(() => {
          if (this.getContacts.length > 0) {
            const keys = Object.keys(this.getContacts[0].extraData);
            keys.forEach(key => {
              if (!this.headers.find((el) => el.text == key)) {
                this.headers.push({
                  text: key,
                  value: 'extraData.' + key, 
                  filterable: false, sortable: false
                });
              }
            });
          }
          this.loading = false;
        })
      },
      deep: true,
    },
  },
  methods: {
    sendInvitation: function(contact) {
      this.$store.commit("app/mask");
      this.$store.dispatch("_import/sendInvitation", contact).finally(() => {
        this.$store.commit("app/unmask");
      });
    },
    sendAllInvitation: function() {
      this.$store.commit("app/mask");
      
      this.$store.dispatch("_import/sendAllInvitation", this.$route.params.uuid).finally(() => {
        window.location.reload();
      });
    }
  }
}
</script>
<style>

</style>