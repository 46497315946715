<template>
  <v-navigation-drawer app permanent>
    <v-list>
      <v-list-item class="px-2">
        <v-img src="@/assets/logo_min.png"></v-img>
      </v-list-item>
      <v-list-item link>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            {{ getUser.firstname }} {{ getUser.lastname }}
          </v-list-item-title>
          <v-list-item-subtitle>{{ getUser.username }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider></v-divider>

    <v-list
      nav
      dense
    >
      <v-list-item link to="/home">
        <v-list-item-icon>
          <v-icon>mdi-home</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Accueil</v-list-item-title>
      </v-list-item>

      <v-list-item v-if="isPaidUser" link to="/imports">
        <v-list-item-icon>
          <v-icon>mdi-table-arrow-down</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Imports</v-list-item-title>
      </v-list-item>

      <v-list-item v-if="isPaidUser" link to="/import/user">
        <v-list-item-icon>
          <v-icon>mdi-microsoft-excel</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Importez des utilisateurs</v-list-item-title>
      </v-list-item>
    </v-list>

    <v-divider></v-divider>

    <v-list
      nav
      dense
    >
      <v-list-item @click="logout">
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Déconnexion</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "Navigation",

  components: { },

  computed: {
    ...mapGetters({
      getUser: 'user/getUser'
    }),
    isPaidUser() {
      return this.getUser ? this.getUser.roles ? this.getUser.roles.includes("ROLE_MANAGER") ? true : false : false : false;
    },
  },

  props: {

  },

  methods: {
    logout: function() {
      this.$store.dispatch('user/logout');
    },
  }
};
</script>