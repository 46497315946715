const env = {};

env.config = {
  api_url: "https://api.soolizi.com/",
  //api_url: "https://api.soolizi.staging.k2project.fr/",
  cdn_url: "https://xxx"
};

env.install = function (Vue) {
  Vue.prototype.$env = env.config;
}

module.exports = env;